<template>
  <div class="sidebar">
    <transition name="fade" appear>
      <div
        class="sidebar-overlay"
        v-if="active"
        @click="$emit('dismiss')"
      ></div>
    </transition>

    <transition name="slide-in-right" appear>
      <section class="sidebar-wrapper" v-if="active">
        <div
          class="sidebar__header"
          v-if="hasHeader"
          :class="{ 'sidebar__header--has-tabs': hasTabs }"
        >
          <slot name="header" />
          <button class="sidebar__close-button" @click="onDismiss">
            <BaseSVG :src="require('@/assets/close-icon.svg')" />
          </button>
        </div>

        <div class="sidebar__tabs" v-if="hasTabs">
          <slot name="tabs" />
        </div>

        <div
          class="sidebar__content"
          :class="{
            'sidebar__content--has-footer': hasFooter,
            'sidebar__content--has-tabs': hasTabs,
          }"
        >
          <slot />
        </div>
        <div class="sidebar__footer" v-if="hasFooter">
          <slot name="footer" />
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";

export default {
  name: "Sidebar",
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    BaseSVG,
  },
  computed: {
    hasHeader() {
      return !!this.$slots.header;
    },
    hasFooter() {
      return !!this.$slots.footer;
    },
    hasTabs() {
      return !!this.$slots.tabs;
    },
  },
  methods: {
    onDismiss() {
      this.$emit("dismiss");
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

$headerHeight: 96px;
$tabsHeight: 40px;
$footerHeight: 64px;

.sidebar {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &-overlay {
    background: $dark-blue-overlay;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 420px;
    height: 100%;
    top: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 -32px 64px $dark-blue-overlay;
    z-index: 10;
  }

  &__header {
    padding: 0 2rem;
    height: $headerHeight;
    display: flex;
    align-items: center;
    border-bottom: solid 1px $disabled;

    &--has-tabs {
      border-bottom: none;
    }

    h2 {
      margin-bottom: 0 !important;
      font-weight: 600;
    }
  }

  &__close-button {
    margin: 0 0 0 auto;
    cursor: pointer;
    border: none;
    background: transparent;
    font-family: inherit;
    padding: 0;

    svg {
      fill: $medium-gray;
      width: 1rem;
    }
  }

  &__tabs {
    width: 100%;
    height: $tabsHeight;
  }

  &__content {
    width: 100%;
    padding: 2rem;
    overflow-y: auto;
    height: calc(100% - #{$headerHeight});

    &--has-tabs {
      height: calc(100% - (#{$headerHeight} + #{$tabsHeight}));
    }

    &--has-footer {
      height: calc(100% - (#{$headerHeight} + #{$footerHeight}));

      &.sidebar__content--has-tabs {
        height: calc(
          100% - (#{$headerHeight} + #{$tabsHeight} + #{$footerHeight})
        );
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footerHeight;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    border-top: solid 2px $fade-gray;
  }
}
</style>