<template>
  <div class="grid-chart__item">
    <div
      class="grid-chart__item-indicator"
      :style="{ opacity }"
      @mouseover="active = true"
      @mouseleave="active = false"
    ></div>
    <transition name="fade-up">
      <div class="grid-chart__item-popover" v-if="active">
        <div class="grid-chart__item-popover-label">Total</div>
        <div class="grid-chart__item-popover-metric">
          {{ metric }}
        </div>
        <div class="grid-chart__item-popover-context">
          {{ percentOfTotal }} of Total
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import numeral from "numeral";

export default {
  name: "GridChartItem",
  props: {
    metric: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    opacity() {
      if (this.max && this.metric) {
        return numeral(this.metric / this.max).format("0%");
      }

      return 0.075;
    },
    percentOfTotal() {
      if (this.total) {
        return numeral(this.metric / this.total).format("0%");
      }

      return "0%";
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.425s,
    transform 0.425s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.fade-up-enter,
.fade-up-leave-to {
  opacity: 0;
  transform: translate3d(0, 16px, 0);
}
.fade-up-enter-to {
  transform: translate3d(0, 0, 0);
}

.grid-chart__item {
  position: relative;
  flex-grow: 1;
  height: 32px;
  padding: 1px;
  margin: 1px;
  display: flex;
  align-items: center;
  justify-content: center;

  &-indicator {
    cursor: pointer;
    display: block;
    position: absolute;
    background-color: $light-blue-tint1;
    width: 100%;
    height: 100%;
    transition: background-color 0.425s;

    &:hover {
      opacity: 1 !important;
      background-color: $light-blue-tint0;
    }
  }

  &-popover {
    display: block;
    position: absolute;
    right: 0;
    top: 100%;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 192px;
    height: 96px;
    background: #fff;
    z-index: 1;
    padding: 16px;

    &-label,
    &-context {
      font-size: 13px;
      color: $medium-gray;
    }

    &-metric {
      font-size: 20px;
      font-weight: 600;
      margin: 8px 0;
      color: $dark-blue;
      position: relative;
      padding-left: 16px;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -4px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: $light-blue-tint0;
      }
    }
  }
}
</style>