var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ad-report"},[_c('div',{staticClass:"ad-report__icon",class:{ alert: _vm.alerts.length }},[(_vm.alerts.length)?_c('CircleIcon',{attrs:{"icon":'warning',"alert":true,"pinned":true,"xl":true}}):_c('CircleIcon',{attrs:{"icon":'check-mark',"xl":true}})],1),_c('div',{staticClass:"card",on:{"click":_vm.onAdReport}},[_c('BaseSVG',{staticClass:"ad-report__arrow-icon",attrs:{"src":require('@/assets/arrow-45-icon.svg')}}),_c('h2',[_c('div',{staticClass:"ad-report__heading"},[_c('div',{staticClass:"ad-report__heading-label"},[_c('CircleIcon',{attrs:{"icon":'ad'}}),(_vm.ad.preview_url)?_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.onPreview(_vm.ad.preview_url)}}},[_vm._v(_vm._s(_vm.ad.name))]):_c('span',[_vm._v(_vm._s(_vm.ad.name))])],1),_c('div',{staticClass:"ad-report__heading-label"},[_c('CircleIcon',{attrs:{"icon":'lander'}}),(_vm.ad.view)?_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.onPreview((_vm.baseURL + "/" + (_vm.campaign.slug) + "/" + (_vm.ad.view.slug)))}}},[_vm._v(_vm._s(_vm.ad.view.name))]):_c('span',[_vm._v(_vm._s(_vm.ad.view.name))])],1)]),_c('div',{staticClass:"ad-report__metrics"},[_c('SimpleMetric',{attrs:{"label":'Total Leads',"measure":_vm.metrics.labels.total_leads,"context":_vm.metrics.labels.conversion_rate,"alert":_vm.$_benchmarks_compareBenchmark(
              'conversion_rate',
              _vm.metrics.conversion_rate
            )}}),_c('SimpleMetric',{attrs:{"label":'Leads Won',"measure":_vm.metrics.labels.total_leads_won,"context":_vm.metrics.labels.conversion_rate_won,"alert":_vm.$_benchmarks_compareBenchmark(
              'conversion_rate_won',
              _vm.metrics.conversion_rate_won
            )}}),_c('SimpleMetric',{attrs:{"label":'CPL Won',"measure":_vm.metrics.labels.cost_per_lead_won,"alert":_vm.$_benchmarks_compareBenchmark(
              'cost_per_lead_won',
              _vm.metrics.cost_per_lead_won
            )}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }