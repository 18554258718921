<template>
  <div class="impact-gauge">
    <div
      class="impact-gauge__item"
      v-for="n in itemCount"
      :key="`item-${n}`"
      :class="{ 'impact-gauge__item--active': n <= impact }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ImpactGauge",
  props: {
    impact: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      itemCount: 4,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
.impact-gauge {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__item {
    width: 3px;
    height: 100%;
    background: $light-gray;

    &:nth-child(1) {
      height: 25%;
    }
    &:nth-child(2) {
      height: 50%;
    }
    &:nth-child(3) {
      height: 75%;
    }

    &--active {
      background: $green;
    }
  }
}
</style>