<template>
  <div class="card">
    <h2>{{ heading }}</h2>
    <Tooltip />
    <div class="metric-wrapper">
      <span class="metric">{{ metric }}</span>
      <span>{{ description }}</span>
    </div>
    <footer v-if="context">
      <span>{{ context }}</span>
      <span>{{ contextMetric }}</span>
    </footer>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip";

export default {
  name: "KPI",
  props: ["heading", "metric", "description", "context", "contextMetric"],
  components: {
    Tooltip,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors";

.card {
  min-height: 100px;
  position: relative;

  .tooltip {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  & .card {
    .tooltip {
      top: 0;
      right: 0;
    }
  }

  .metric-wrapper {
    margin-top: 1.75rem;
    display: flex;

    .metric {
      font-size: 2.25rem;
      font-weight: 400;
    }

    span:last-of-type {
      margin-left: auto;
      font-size: 0.875rem;
      margin-top: auto;
      margin-bottom: 6px;
    }
  }

  footer {
    display: flex;
    margin-top: 2.5rem;
    color: $medium-gray;
    font-size: 0.875rem;

    span:last-of-type {
      margin-left: auto;
    }
  }

  &.highlight {
    footer {
      color: #fff;
    }
  }
}
</style>