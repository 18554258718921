<template>
  <div class="campaign-report">
    <Loading :active="loading" :fullscreen="true" />
    <AdReportSidebar
      :active="adReportSidebarActive"
      :campaign="campaign"
      :ad="adReportData"
      @dismiss="adReportSidebarActive = false"
    />
    <div class="hero hero--optimize">
      <div class="container">
        <h2>{{ campaign.name }}</h2>
        <div class="select-wrapper form-control">
          <div class="select">
            <date-range-picker
              ref="picker"
              :locale-data="{ firstDay: 1, format: 'mm-dd-yyyy' }"
              :autoApply="false"
              v-model="dateRange"
              :opens="'left'"
              @update="getCampaignData"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </template>
              <template #date="data">
                <span class="small">{{ data.date | dateCell }}</span>
                <template v-if="hasTrackingEvent(data.date, campaign.id)">
                  <span class="marker"> &bull; </span>
                  <span class="tooltip">{{
                    trackingEventLabel(data.date, campaign.id)
                  }}</span>
                </template>
              </template>
            </date-range-picker>
            <BaseSVG :src="require('@/assets/calendar-icon.svg')" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="campaign-report__kpi-wrapper">
        <div class="campaign-report__kpi-wrapper-card">
          <KPI
            :heading="'Total Engaged Sessions'"
            :metric="metrics.labels.total_engaged_sessions"
            :description="`${metrics.labels.engagement_rate} Engagement Rate`"
            :context="'Total Sessions'"
            :contextMetric="metrics.labels.total_sessions"
          />
        </div>
        <div class="campaign-report__kpi-wrapper-card">
          <KPI
            :heading="'Total Leads Won'"
            :metric="metrics.labels.total_leads_won"
            :description="`${metrics.labels.conversion_rate_won} Conversion Rate`"
            :context="'Total Leads'"
            :contextMetric="metrics.labels.total_leads"
          />
        </div>
        <div class="campaign-report__kpi-wrapper-card">
          <KPI
            :heading="'Cost Per Lead Won'"
            :metric="metrics.labels.cost_per_lead_won"
            :description="`${metrics.labels.cost_per_lead} Cost Per Lead`"
            :context="'Total Cost'"
            :contextMetric="metrics.labels.total_cost"
          />
        </div>
      </div>

      <div class="card">
        <h2>Lead Comparison By Platform</h2>
        <div class="campaign-report__rollup">
          <div class="campaign-report__rollup-doughnut-chart">
            <div class="campaign-report__rollup-doughnut-chart-wrapper">
              <div class="chart-metric-wrapper">
                <span class="metric">{{ metrics.labels.total_leads }}</span>
                <span class="label">Total Leads</span>
              </div>
              <doughnut-chart
                :height="240"
                :chart-data="doughnutChartData"
                :tooltips="{}"
              />
            </div>
            <ListLegend :list="totalLeadsByPlatform" />
          </div>
          <div class="campaign-report__rollup-bar-chart">
            <BarChart
              :chart-data="barChartData"
              :height="180"
              :tooltips="tooltips"
            />
            <div class="graph-legend">
              <div
                class="graph-legend-item"
                v-for="(item, index) in chartLegend"
                :key="`chart-legend-item-${index}`"
              >
                <span
                  class="graph-legend-item-indicator"
                  :style="getChartLegendStyle(index)"
                ></span>
                <span class="graph-legend-item-label">{{ item.label }}</span>
              </div>
            </div>
          </div>
          <div class="campaign-report__rollup-win-percentage">
            <div class="form-control">
              <select v-model="leadsByPlatformFilter">
                <option :value="null">All Platforms</option>
                <option
                  v-for="(item, index) in chartLegend"
                  :key="`Platform-option-${index}`"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
            <div class="graph-metrics">
              <div class="graph-metrics__metric">
                <span class="graph-metrics__metric-label">Total Win Rate</span>
                <h3>
                  <span>{{ totalLeadsPercentageWonByPlatform | percent }}</span>
                </h3>
              </div>
              <div class="graph-metrics__metric">
                <span class="graph-metrics__metric-label"
                  >Inbound Win Rate</span
                >
                <h3>
                  <span>{{
                    inboundLeadsPercentageWonByPlatform | percent
                  }}</span>
                </h3>
              </div>
              <div class="graph-metrics__metric">
                <span class="graph-metrics__metric-label"
                  >Outbound Win Rate</span
                >
                <h3>
                  <span>{{
                    outboundLeadsPercentageWonByPlatform | percent
                  }}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2>
        <span>Leads By Geography &amp; Environment</span>
        <div class="select-wrapper form-control">
          <select v-model="regionFilter" :disabled="geometryLoading">
            <option :value="null">All Regions</option>
            <option
              :value="region"
              v-for="(region, index) in regions"
              :key="`region-${index}`"
            >
              {{ region }}
            </option>
          </select>
        </div>
      </h2>

      <div class="campaign-report__access-wrapper">
        <div class="card">
          <div id="map-canvas">
            <Loading :small="true" :active="geometryLoading" />
            <gmap-map
              ref="mapRef"
              :center="{ lat: 37.0902, lng: -95.7129 }"
              :zoom="5"
              :map-type-id="'roadmap'"
              :options="{
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                zoomControl: false,
                styles: mapStyle,
              }"
            >
              <gmap-marker
                v-for="(location, index) in locations"
                :key="`marker-${index}`"
                :position="{
                  lat: parseFloat(location.lat),
                  lng: parseFloat(location.lng),
                }"
                :icon="markerIcon"
                :zIndex="1"
              ></gmap-marker>
            </gmap-map>
          </div>
        </div>
        <div class="card">
          <h2>
            <span>Healthcare Environment</span>
            <div class="select-wrapper form-control">
              <select v-model="environmentFilter">
                <option :value="null">All Leads</option>
                <option :value="'won'">Closed Won</option>
                <option :value="'not_ready'">Not Ready</option>
              </select>
            </div>
          </h2>
          <GridChart :chartData="gridChartData" />
        </div>
      </div>

      <h2>Ad To Landing Page Performance</h2>
      <AdReport
        v-for="ad in filteredAds"
        :key="`ad-${ad.id}`"
        :ad="ad"
        @on-ad-report="onAdReport"
      ></AdReport>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseSVG from "@/components/base/BaseSVG";
import DoughnutChart from "@/components/charts/DoughnutChart";
import BarChart from "@/components/charts/BarChart";
import ListLegend from "@/components/ListLegend";
import Loading from "@/components/Loading";
import KPI from "@/components/KPI";
import AdReport from "@/components/AdReport";
import AdReportSidebar from "@/components/AdReportSidebar";
import { colors } from "@/mixins/colors";
import { reports } from "@/mixins/reports";
import voca from "voca";
import numeral from "numeral";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { trackingEvents } from "@/mixins/trackingEvents";
import GridChart from "@/components/charts/GridChart";
import { maps } from "@/mixins/maps";
import { gmapApi } from "vue2-google-maps";
import styles from "@/mixins/mapStyles";

const today = new Date();
const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

export default {
  name: "CampaignReport",
  mixins: [colors, reports, trackingEvents, maps, styles],
  components: {
    BaseSVG,
    DoughnutChart,
    BarChart,
    ListLegend,
    Loading,
    KPI,
    AdReport,
    AdReportSidebar,
    DateRangePicker,
    GridChart,
  },
  data() {
    return {
      leadsByPlatformFilter: null,
      adReportSidebarActive: false,
      adReportData: null,
      adInsights: [],
      dateRange: {
        startDate: oneWeekAgo,
        endDate: today,
      },
      environmentFilter: null,
      regionFilter: null,
      mapStyle: styles,
      geometryLoading: false,
      geometry: null,
    };
  },
  computed: {
    campaign() {
      return this.$store.getters["optimize/campaign"];
    },
    ads() {
      return this.campaign ? this.campaign.ads : [];
    },
    filteredAds() {
      return this.ads.filter((ad) => {
        return ad.reports.length;
      });
    },
    reports() {
      let list = [];
      this.ads.map((ad) => {
        if (ad.reports.length) {
          list = [...list, ...ad.reports];
        }
      });

      return list;
    },
    metrics() {
      return this.$_reports_reduceAdReports(this.reports);
    },
    loading() {
      return this.$store.getters["optimize/loading"];
    },
    leadsByPlatform() {
      let platforms = {};
      this.filteredAds.map((ad) => {
        if (typeof platforms[ad.source] == "undefined") {
          platforms[ad.source] = {
            label: voca.capitalize(ad.source),
            reports: [],
            metrics: null,
          };
        }
        // Add individual reports
        platforms[ad.source].reports = [
          ...ad.reports,
          ...platforms[ad.source].reports,
        ];
      });

      // Reduce reports by platform
      for (let key in platforms) {
        platforms[key].metrics = this.$_reports_reduceAdReports(
          platforms[key].reports
        );
      }

      return platforms;
    },
    totalLeadsByPlatform() {
      let list = [];
      let count = 0;
      for (let key in this.leadsByPlatform) {
        list.push({
          label: this.leadsByPlatform[key].label,
          total: this.leadsByPlatform[key].metrics.total_leads,
          color: this.colors[`lightBlueTint${count}`],
        });
        count++;
      }
      return list;
    },
    doughnutChartData() {
      let data = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            borderColor: [],
          },
        ],
      };

      let count = 0;
      for (let key in this.leadsByPlatform) {
        data.labels.push(this.leadsByPlatform[key].label);
        data.datasets[0].data.push(
          this.leadsByPlatform[key].metrics.total_leads
        );
        data.datasets[0].backgroundColor.push(
          this.colors[`lightBlueTint${count}`]
        );
        data.datasets[0].borderColor.push("#FFF");
        count++;
      }

      return data;
    },
    barChartData() {
      let datasets = [];
      let count = 0;
      for (let key in this.leadsByPlatform) {
        datasets.push({
          backgroundColor: this.colors[`lightBlueTint${count}`],
          borderColor: "transparent",
          data: [
            this.leadsByPlatform[key].metrics.total_leads_inbound,
            this.leadsByPlatform[key].metrics.total_leads_outbound,
            this.leadsByPlatform[key].metrics.total_leads_won,
            this.leadsByPlatform[key].metrics.total_leads_won_inbound,
            this.leadsByPlatform[key].metrics.total_leads_won_outbound,
          ],
          barPercentage: 0.75,
          categoryPercentage: 0.5,
        });
        count++;
      }
      return {
        labels: [
          "Inbound Leads",
          "Outbound Leads",
          "Leads Won",
          "Inbound Won",
          "Outbound Won",
        ],
        datasets: datasets,
      };
    },
    tooltips() {
      return {
        callbacks: {
          label: function (tooltipItem) {
            return numeral(tooltipItem.value).format("0,0");
          },
          title: function () {
            return null;
          },
        },
      };
    },
    chartLegend() {
      let list = [];
      for (let key in this.leadsByPlatform) {
        list.push({
          label: this.leadsByPlatform[key].label,
          value: key,
        });
      }
      return list;
    },
    totalLeadsPercentageWonByPlatform() {
      if (!this.leadsByPlatformFilter) {
        return this.metrics.total_leads
          ? this.metrics.total_leads_won / this.metrics.total_leads
          : 0;
      } else {
        if (this.leadsByPlatform) {
          return this.leadsByPlatform[this.leadsByPlatformFilter].metrics
            .total_leads
            ? this.leadsByPlatform[this.leadsByPlatformFilter].metrics
                .total_leads_won /
                this.leadsByPlatform[this.leadsByPlatformFilter].metrics
                  .total_leads
            : 0;
        }
      }

      return null;
    },
    inboundLeadsPercentageWonByPlatform() {
      if (!this.leadsByPlatformFilter) {
        return this.metrics.total_leads
          ? this.metrics.total_leads_won_inbound /
              this.metrics.total_leads_inbound
          : 0;
      } else {
        if (this.leadsByPlatform) {
          return this.leadsByPlatform[this.leadsByPlatformFilter].metrics
            .total_leads_inbound
            ? this.leadsByPlatform[this.leadsByPlatformFilter].metrics
                .total_leads_won_inbound /
                this.leadsByPlatform[this.leadsByPlatformFilter].metrics
                  .total_leads_inbound
            : 0;
        }
      }

      return null;
    },
    outboundLeadsPercentageWonByPlatform() {
      if (!this.leadsByPlatformFilter) {
        return this.metrics.total_leads
          ? this.metrics.total_leads_won_outbound /
              this.metrics.total_leads_outbound
          : 0;
      } else {
        if (this.leadsByPlatform) {
          return this.leadsByPlatform[this.leadsByPlatformFilter].metrics
            .total_leads_outbound
            ? this.leadsByPlatform[this.leadsByPlatformFilter].metrics
                .total_leads_won_outbound /
                this.leadsByPlatform[this.leadsByPlatformFilter].metrics
                  .total_leads_outbound
            : 0;
        }
      }

      return null;
    },
    locations() {
      return this?.campaign?.locations || [];
    },
    markerIcon() {
      if (this.google) {
        return {
          url: require("@/assets/map-marker.png"),
          size: new this.google.maps.Size(22, 22),
          origin: new this.google.maps.Point(0, 0),
          anchor: new this.google.maps.Point(11, 22),
          scaledSize: new this.google.maps.Size(22, 22),
        };
      }

      return null;
    },
    regions() {
      if (this?.campaign?.geography) {
        return this.campaign.geography.reduce((list, val) => {
          if (val?.county?.region) {
            if (!list.includes(val.county.region)) {
              list.push(val.county.region);
            }
          }
          return list;
        }, []);
      }

      return [];
    },
    gridConversions() {
      // Check for filter
      if (this.environmentFilter) {
        return this.campaign.conversions.filter((conversion) => {
          if (this.environmentFilter === "won") {
            // Check for actions with event_id 2 or 22
            let match = conversion.actions.find((action) => {
              return action.event_id == 2 || action.event_id == 22;
            });

            return typeof match != "undefined";
          } else if (this.environmentFilter === "not_ready") {
            // Check for actions with event_id 7
            let match = conversion.actions.find((action) => {
              return action.event_id == 7;
            });

            return typeof match != "undefined";
          }
        });
      }

      return this.campaign.conversions;
    },
    gridConversionsGeography() {
      return this.gridConversions.reduce((list, val) => {
        if (!list.includes(val.zip_code)) {
          list.push(val.zip_code);
        }
        return list;
      }, []);
    },
    gridChartData() {
      let data = [
        [0, 0, 0], // High access
        [0, 0, 0], // Moderate access
        [0, 0, 0], // Low access
        // [Rural, Suburban, Urban]
      ];
      if (this?.campaign?.conversions) {
        // Loop through conversions
        // Set access index & environment index
        this.gridConversions.map((conversion) => {
          let accessArray = null;
          let index = null;
          let geo = this.getConversionGeography(conversion.zip_code);
          if (!this.regionFilter || geo?.county?.region == this.regionFilter) {
            if (typeof geo != "undefined") {
              if (geo.cluster_id) {
                if (geo.cluster_id == 1 || geo.cluster_id == 3) {
                  accessArray = data[2];
                } else if (geo.cluster_id == 2) {
                  accessArray = data[1];
                } else if (geo.cluster_id == 4) {
                  accessArray = data[0];
                }

                if (geo.county && geo.county.classification) {
                  if (geo.county.classification == "rural") {
                    index = 0;
                  } else if (geo.county.classification == "suburban") {
                    index = 1;
                  } else if (geo.county.classification == "urban") {
                    index = 2;
                  }

                  if (index != null) {
                    accessArray[index]++;
                  }
                }
              }
            }
          }
        });
      }

      return {
        labels: [
          ["High Access", "Moderate Access", "Low Access"],
          ["Rural", "Suburban", "Urban"],
        ],
        data,
      };
    },
    google() {
      return gmapApi();
    },
  },
  methods: {
    getCampaignData() {
      const id = this.$route.params.id;
      const range = JSON.stringify(this.dateRange);
      this.$store
        .dispatch("optimize/getCampaign", {
          id: id,
          range,
        })
        .then((res) => {
          if (res.status < 300 && res.data.geography) {
            this.geometryLoading = true;
            let list = [];
            res.data.geography.map((item) => {
              if (list.indexOf(item.zip_code) === -1) {
                list.push(item.zip_code);
              }
            });

            const zipCodes = list.join(",");

            // Get geometry of zip codes
            axios
              .get(
                `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/service/audiences/get-geometry?zip_codes=${zipCodes}`
              )
              .then((res) => {
                this.geometry = res.data.map((item) => {
                  return {
                    geom: item.zip_code_geom,
                    properties: {
                      zip_code: item.zip_code,
                      cluster_id: item.cluster_id,
                      region: item?.county?.region,
                    },
                  };
                });

                this.drawGeometry();
              })
              .catch((err) => {
                console.log(err);
              })
              .then(() => {
                this.geometryLoading = false;
              });
          }
        });
    },
    getChartLegendStyle(index) {
      return {
        "background-color": this.colors[`lightBlueTint${index}`],
      };
    },
    onAdReport(ad) {
      this.adReportData = ad;
      this.adReportSidebarActive = true;
    },
    getConversionGeography(zip) {
      return this.campaign.geography.find((item) => {
        return item.zip_code == zip;
      });
    },
    getStyle(feature) {
      let style = {
        fillColor: this.colors.lightBlueTint2,
        fillOpacity: 0.35,
        strokeColor: this.colors.lightBlueTint0,
        strokeWeight: 1,
        strokeOpacity: 0.5,
      };

      // Check if zip code in list
      if (
        this.gridConversionsGeography.includes(feature.getProperty("zip_code"))
      ) {
        style.fillColor = this.colors.lightBlueTint0;
        style.fillOpacity = 0.75;
        style.strokeColor = this.colors.lightBlueTint0;
        style.strokeOpacity = 0.875;
      }

      return style;
    },
    drawGeometry() {
      if (this?.campaign?.geography) {
        this.$_maps_drawGeometry(
          this.geometry,
          this.$refs.mapRef,
          this.google,
          this.getStyle
        );
      }
    },
  },
  mounted() {
    this.getCampaignData();
    this.$store.dispatch("conversions/getReportingData");
  },
  watch: {
    regionFilter() {
      this.$_maps_zoomToBounds(
        { region: this.regionFilter },
        this.$refs.mapRef,
        this.google
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/hero";
@import "@/scss/forms";
@import "@/scss/date_range";

#map-canvas {
  width: calc(100% + 3rem);
  height: calc(100% + 3rem);
  margin: -1.5rem;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.experiments {
  display: flex;
  flex-wrap: wrap;

  .experiment-card {
    width: calc(50% - 0.75rem);

    &:nth-child(odd) {
      margin-right: 0.75rem;
    }

    &:nth-child(even) {
      margin-left: 0.75rem;
    }
  }
}
.menu__list {
  margin: -1.5rem;
  padding: 0.5rem 0;
}

.campaign-report {
  .hero {
    .container {
      .select-wrapper {
        margin-left: auto;
        display: flex;
        min-width: 256px;

        .select {
          width: 100%;
          margin-left: 32px;
          position: relative;

          select {
            margin-bottom: 0;
          }

          .vue-daterange-picker {
            width: 100%;
          }

          svg {
            position: absolute;
            right: -6px;
            height: 0.75em;
            top: 50%;
            margin-top: calc(-0.3625em - 2px);

            fill: #fff;
          }
        }
      }
    }
  }

  .graph-legend {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    &-item {
      display: flex;
      align-items: center;
      margin: 0 1rem;

      &-indicator {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
      }

      &-label {
        font-size: 0.75rem;
        color: $medium-gray;
        margin-left: 0.25rem;
      }
    }

    &.graph-legend-top {
      margin-top: 0;
      margin-bottom: 1rem;
      justify-content: flex-start;

      .graph-legend-item {
        margin: 0;

        &-label {
          margin-left: 0;
        }
      }
    }

    &-item:first-of-type {
      .graph-legend-item-indicator {
        background: $light-blue;
      }
    }
    &-item:last-of-type {
      .graph-legend-item-indicator {
        background: $blue;
      }
    }
  }

  &__rollup {
    display: flex;

    &-doughnut-chart {
      width: calc(33.3333% - 2rem);
      border-right: solid 2px $fade-gray;
      padding-right: 2rem;

      &-wrapper {
        position: relative;
        & + .list-legend {
          margin-top: 2rem;
        }
      }

      .chart-metric-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        pointer-events: none;
        z-index: 0;

        & + div {
          position: relative;
          z-index: 1;
        }

        .metric {
          font-size: 2.25rem;
          font-weight: 600;
        }

        .label {
          font-size: 0.75rem;
          color: $medium-gray;
        }
      }
    }
    &-bar-chart {
      width: calc(51.6666% + 2rem);
      padding-left: 2rem;
    }
    &-win-percentage {
      width: 15%;
      padding-left: 1rem;

      .form-control {
        select {
          margin-bottom: 4px !important;
        }
      }
    }
  }

  &__access-wrapper {
    display: flex;

    .card {
      width: calc(50% - 0.75rem);

      &:last-of-type {
        margin-left: auto;
      }
    }
  }

  .card {
    margin-bottom: 2rem;

    &.ad-report {
      margin-bottom: 1.5rem;
    }
  }

  h2 {
    .select-wrapper {
      margin-left: auto;
      display: flex;

      select {
        min-width: 192px;
        margin-bottom: 0;
        margin-right: 16px;

        &:last-of-type {
          margin-right: 0;
        }

        & + .button {
          margin-left: 1rem !important;
        }
      }
    }
  }

  &__kpi-wrapper {
    display: flex;
    margin-left: -12px;
    margin-right: -12px;

    &-card {
      width: 33.3333%;
      padding: 0 12px;
    }
  }

  .graph-metrics {
    display: flex;
    flex-direction: column;
    align-items: space-between;

    &__metric {
      padding: 1rem 1rem 1rem 0;
      border-bottom: solid 1px #e9e9e9;
      position: relative;
      text-align: right;

      &:last-of-type {
        border-bottom: none;
      }

      &-label {
        display: block;
        margin-bottom: 0.375rem;
        font-size: 0.75rem;
        color: $medium-gray;
      }

      h3 {
        span {
          font-size: 20px;
          font-weight: 600;
          color: $dark-blue;
        }

        .metric--alert {
          color: $red !important;
        }
      }
    }
  }
}
</style>