import numeral from 'numeral';
import AdReport from "@/models/AdReport";

export const reports = {
	methods: {
		$_reports_reduceAdReports(reports) {
			let data = new AdReport();

			let totalScrollDepth = 0;
			let totalSessionDuration = 0;
			let totalEngagedScrollDepth = 0;
			let totalEngagedSessionDuration = 0;

			reports.map((report) => {
				data.clicks += (report.clicks) ? report.clicks : 0;
				data.impressions += (report.impressions) ? report.impressions : 0;
				data.total_sessions += (report.total_sessions) ? report.total_sessions : 0;
				data.total_engaged_sessions += (report.total_engaged_sessions) ? report.total_engaged_sessions : 0;
				data.total_leads += (report.total_leads) ? report.total_leads : 0;
				data.total_leads_inbound += (report.total_leads_inbound) ? report.total_leads_inbound : 0;
				data.total_leads_outbound += (report.total_leads_outbound) ? report.total_leads_outbound : 0;
				data.total_leads_won += (report.total_leads_won) ? report.total_leads_won : 0;
				data.total_leads_won_inbound += (report.total_leads_won_inbound) ? report.total_leads_won_inbound : 0;
				data.total_leads_won_outbound += (report.total_leads_won_outbound) ? report.total_leads_won_outbound : 0;
				data.total_cost += (report.total_cost) ? report.total_cost : 0;
				totalScrollDepth += (report.total_sessions && report.average_session_scroll_depth) ? report.average_session_scroll_depth * report.total_sessions : 0;
				totalEngagedScrollDepth += (report.total_engaged_sessions && report.average_engaged_session_scroll_depth) ? report.average_engaged_session_scroll_depth * report.total_engaged_sessions : 0;
				totalSessionDuration += (report.total_sessions && report.average_session_duration) ? report.average_session_duration * report.total_sessions : 0;
				totalEngagedSessionDuration += (report.total_engaged_sessions && report.average_engaged_session_duration) ? report.average_engaged_session_duration * report.total_engaged_sessions : 0;
				data.total_leads_invalid += (report.total_leads_invalid) ? report.total_leads_invalid : 0;
				data.total_leads_invalid_inbound += (report.total_leads_invalid_inbound) ? report.total_leads_invalid_inbound : 0;
				data.total_leads_invalid_outbound += (report.total_leads_invalid_outbound) ? report.total_leads_invalid_outbound : 0;
				data.total_leads_qualified += (report.total_leads_qualified) ? report.total_leads_qualified : 0;
				data.total_leads_qualified_inbound += (report.total_leads_qualified_inbound) ? report.total_leads_qualified_inbound : 0;
				data.total_leads_qualified_outbound += (report.total_leads_qualified_outbound) ? report.total_leads_qualified_outbound : 0;
				data.total_leads_unqualified += (report.total_leads_unqualified) ? report.total_leads_unqualified : 0;
				data.total_leads_unqualified_inbound += (report.total_leads_unqualified_inbound) ? report.total_leads_unqualified_inbound : 0;
				data.total_leads_unqualified_outbound += (report.total_leads_unqualified_outbound) ? report.total_leads_unqualified_outbound : 0;
				data.total_leads_unqualified_insurance += (report.total_leads_unqualified_insurance) ? report.total_leads_unqualified_insurance : 0;
				data.total_leads_unqualified_insurance_inbound += (report.total_leads_unqualified_insurance_inbound) ? report.total_leads_unqualified_insurance_inbound : 0;
				data.total_leads_unqualified_insurance_outbound += (report.total_leads_unqualified_insurance_outbound) ? report.total_leads_unqualified_insurance_outbound : 0;
				data.total_leads_connected += (report.total_leads_connected) ? report.total_leads_connected : 0;
				data.total_leads_connected_inbound += (report.total_leads_connected_inbound) ? report.total_leads_connected_inbound : 0;
				data.total_leads_connected_outbound += (report.total_leads_connected_outbound) ? report.total_leads_connected_outbound : 0;
			});

			// Calculate percentages and format
			data.click_through_rate = (data.impressions) ? data.clicks / data.impressions : 0;
			data.engagement_rate = (data.clicks) ? data.total_engaged_sessions / data.clicks : 0;
			data.conversion_rate = (data.total_sessions) ? data.total_leads / data.total_sessions : 0;
			data.conversion_rate_won = (data.total_leads) ? data.total_leads_won / data.total_leads : 0;
			data.conversion_rate_won_inbound = (data.total_leads_inbound) ? data.total_leads_won_inbound / data.total_leads_inbound : 0;
			data.conversion_rate_won_outbound = (data.total_leads_outbound) ? data.total_leads_won_outbound / data.total_leads_outbound : 0;
			data.cost_per_lead = (data.total_leads) ? data.total_cost / data.total_leads : 0;
			data.cost_per_lead_won = (data.total_leads_won) ? data.total_cost / data.total_leads_won : 0;
			data.cost_per_click = (data.clicks) ? data.total_cost / data.clicks : 0;
			data.average_session_scroll_depth = (data.total_sessions) ? totalScrollDepth / data.total_sessions : 0;
			data.average_engaged_session_scroll_depth = (data.total_engaged_sessions) ? totalEngagedScrollDepth / data.total_engaged_sessions : 0;
			data.average_session_duration = (data.total_sessions) ? totalSessionDuration / data.total_sessions : 0;
			data.average_engaged_session_duration = (data.total_engaged_sessions) ? totalEngagedSessionDuration / data.total_engaged_sessions : 0;
			data.total_leads_invalid_rate = (data.total_leads) ? data.total_leads_invalid / data.total_leads : 0;
			data.total_leads_invalid_inbound_rate = (data.total_leads_inbound) ? data.total_leads_invalid_inbound / data.total_leads_inbound : 0;
			data.total_leads_invalid_outbound_rate = (data.total_leads_outbound) ? data.total_leads_invalid_outbound / data.total_leads_outbound : 0;
			data.total_leads_qualified_rate = (data.total_leads) ? data.total_leads_qualified / data.total_leads : 0;
			data.total_leads_qualified_inbound_rate = (data.total_leads_inbound) ? data.total_leads_qualified_inbound / data.total_leads_inbound : 0;
			data.total_leads_qualified_outbound_rate = (data.total_leads_outbound) ? data.total_leads_qualified_outbound / data.total_leads_outbound : 0;
			data.total_leads_unqualified_rate = (data.total_leads) ? data.total_leads_unqualified / data.total_leads : 0;
			data.total_leads_unqualified_inbound_rate = (data.total_leads_inbound) ? data.total_leads_unqualified_inbound / data.total_leads_inbound : 0;
			data.total_leads_unqualified_outbound_rate = (data.total_leads_outbound) ? data.total_leads_unqualified_outbound / data.total_leads_outbound : 0;
			data.total_leads_unqualified_insurance_rate = (data.total_leads) ? data.total_leads_unqualified_insurance / data.total_leads : 0;
			data.total_leads_unqualified_insurance_inbound_rate = (data.total_leads_inbound) ? data.total_leads_unqualified_insurance_inbound / data.total_leads_inbound : 0;
			data.total_leads_unqualified_insurance_outbound_rate = (data.total_leads_outbound) ? data.total_leads_unqualified_insurance_outbound / data.total_leads_outbound : 0;
			data.total_leads_connected_rate = (data.total_leads) ? data.total_leads_connected / data.total_leads : 0;
			data.total_leads_connected_inbound_rate = (data.total_leads_inbound) ? data.total_leads_connected_inbound / data.total_leads_inbound : 0;
			data.total_leads_connected_outbound_rate = (data.total_leads_outbound) ? data.total_leads_connected_outbound / data.total_leads_outbound : 0;
			data.cost_per_lead_connected = (data.total_leads_connected) ? data.total_cost / data.total_leads_connected : 0;
			data.cost_per_lead_qualified = (data.total_leads_qualified) ? data.total_cost / data.total_leads_qualified : 0;

			// Format
			data.labels.clicks = numeral(data.clicks).format('0,0');
			data.labels.impressions = numeral(data.impressions).format('0,0a');
			data.labels.total_sessions = numeral(data.total_sessions).format('0,0');
			data.labels.total_engaged_sessions = numeral(data.total_engaged_sessions).format('0,0');
			data.labels.total_leads = numeral(data.total_leads).format('0,0');
			data.labels.total_leads_inbound = numeral(data.total_leads_inbound).format('0,0');
			data.labels.total_leads_outbound = numeral(data.total_leads_outbound).format('0,0');
			data.labels.total_leads_won = numeral(data.total_leads_won).format('0,0');
			data.labels.total_leads_won_inbound = numeral(data.total_leads_won_inbound).format('0,0');
			data.labels.total_leads_won_outbound = numeral(data.total_leads_won_outbound).format('0,0');
			data.labels.total_cost = numeral(data.total_cost).format('$0,0');
			data.labels.click_through_rate = (data.click_through_rate) ? numeral(data.click_through_rate).format('0.00%') : "-";
			data.labels.engagement_rate = (data.engagement_rate) ? numeral(data.engagement_rate).format('%') : "-";
			data.labels.conversion_rate = (data.conversion_rate) ? numeral(data.conversion_rate).format('0.00%') : "-";
			data.labels.conversion_rate_won = (data.conversion_rate_won) ? numeral(data.conversion_rate_won).format('0.00%') : "-";
			data.labels.conversion_rate_won_inbound = (data.conversion_rate_won_inbound) ? numeral(data.conversion_rate_won_inbound).format('0.00%') : "-";
			data.labels.conversion_rate_won_outbound = (data.conversion_rate_won_outbound) ? numeral(data.conversion_rate_won_outbound).format('0.00%') : "-";
			data.labels.cost_per_lead = (data.cost_per_lead) ? numeral(data.cost_per_lead).format('$0,0') : "-";
			data.labels.cost_per_lead_won = (data.cost_per_lead_won) ? numeral(data.cost_per_lead_won).format('$0,0') : "-";
			data.labels.cost_per_click = (data.cost_per_click) ? numeral(data.cost_per_click).format('$0,0') : "-";
			data.labels.average_session_scroll_depth = (data.average_session_scroll_depth) ? numeral(data.average_session_scroll_depth).format('0.00%') : "-";
			data.labels.average_engaged_session_scroll_depth = (data.average_engaged_session_scroll_depth) ? numeral(data.average_engaged_session_scroll_depth).format('0.00%') : "-";
			data.labels.average_session_duration = (data.average_session_duration) ? numeral(data.average_session_duration).format('00:00:00') : "-";
			data.labels.average_engaged_session_duration = (data.average_engaged_session_duration) ? numeral(data.average_engaged_session_duration).format('00:00:00') : "-";

			data.labels.total_leads_invalid = numeral(data.total_leads_invalid).format('0,0');
			data.labels.total_leads_invalid_inbound = numeral(data.total_leads_invalid_inbound).format('0,0');
			data.labels.total_leads_invalid_outbound = numeral(data.total_leads_invalid_outbound).format('0,0');
			data.labels.total_leads_qualified = numeral(data.total_leads_qualified).format('0,0');
			data.labels.total_leads_qualified_inbound = numeral(data.total_leads_qualified_inbound).format('0,0');
			data.labels.total_leads_qualified_outbound = numeral(data.total_leads_qualified_outbound).format('0,0');
			data.labels.total_leads_unqualified = numeral(data.total_leads_unqualified).format('0,0');
			data.labels.total_leads_unqualified_inbound = numeral(data.total_leads_unqualified_inbound).format('0,0');
			data.labels.total_leads_unqualified_outbound = numeral(data.total_leads_unqualified_outbound).format('0,0');
			data.labels.total_leads_unqualified_insurance = numeral(data.total_leads_unqualified_insurance).format('0,0');
			data.labels.total_leads_unqualified_insurance_inbound = numeral(data.total_leads_unqualified_insurance_inbound).format('0,0');
			data.labels.total_leads_unqualified_insurance_outbound = numeral(data.total_leads_unqualified_insurance_outbound).format('0,0');
			data.labels.total_leads_connected = numeral(data.total_leads_connected).format('0,0');
			data.labels.total_leads_connected_inbound = numeral(data.total_leads_connected_inbound).format('0,0');
			data.labels.total_leads_connected_outbound = numeral(data.total_leads_connected_outbound).format('0,0');

			data.labels.total_leads_invalid_rate = (data.total_leads_invalid_rate) ? numeral(data.total_leads_invalid_rate).format('0.00%') : "-";
			data.labels.total_leads_invalid_inbound_rate = (data.total_leads_invalid_inbound_rate) ? numeral(data.total_leads_invalid_inbound_rate).format('0.00%') : "-";
			data.labels.total_leads_invalid_outbound_rate = (data.total_leads_invalid_outbound_rate) ? numeral(data.total_leads_invalid_outbound_rate).format('0.00%') : "-";
			data.labels.total_leads_qualified_rate = (data.total_leads_qualified_rate) ? numeral(data.total_leads_qualified_rate).format('0.00%') : "-";
			data.labels.total_leads_qualified_inbound_rate = (data.total_leads_qualified_inbound_rate) ? numeral(data.total_leads_qualified_inbound_rate).format('0.00%') : "-";
			data.labels.total_leads_qualified_outbound_rate = (data.total_leads_qualified_outbound_rate) ? numeral(data.total_leads_qualified_outbound_rate).format('0.00%') : "-";
			data.labels.total_leads_unqualified_rate = (data.total_leads_unqualified_rate) ? numeral(data.total_leads_unqualified_rate).format('0.00%') : "-";
			data.labels.total_leads_unqualified_inbound_rate = (data.total_leads_unqualified_inbound_rate) ? numeral(data.total_leads_unqualified_inbound_rate).format('0.00%') : "-";
			data.labels.total_leads_unqualified_outbound_rate = (data.total_leads_unqualified_outbound_rate) ? numeral(data.total_leads_unqualified_outbound_rate).format('0.00%') : "-";
			data.labels.total_leads_unqualified_insurance_rate = (data.total_leads_unqualified_insurance_rate) ? numeral(data.total_leads_unqualified_insurance_rate).format('0.00%') : "-";
			data.labels.total_leads_unqualified_insurance_inbound_rate = (data.total_leads_unqualified_insurance_inbound_rate) ? numeral(data.total_leads_unqualified_insurance_inbound_rate).format('0.00%') : "-";
			data.labels.total_leads_unqualified_insurance_outbound_rate = (data.total_leads_unqualified_insurance_outbound_rate) ? numeral(data.total_leads_unqualified_insurance_outbound_rate).format('0.00%') : "-";
			data.labels.total_leads_connected_rate = (data.total_leads_connected_rate) ? numeral(data.total_leads_connected_rate).format('0.00%') : "-";
			data.labels.total_leads_connected_inbound_rate = (data.total_leads_connected_inbound_rate) ? numeral(data.total_leads_connected_inbound_rate).format('0.00%') : "-";
			data.labels.total_leads_connected_outbound_rate = (data.total_leads_connected_outbound_rate) ? numeral(data.total_leads_connected_outbound_rate).format('0.00%') : "-";
			data.labels.cost_per_lead_connected = (data.cost_per_lead_connected) ? numeral(data.cost_per_lead_connected).format('$0,0') : "-";
			data.labels.cost_per_lead_qualified = (data.cost_per_lead_qualified) ? numeral(data.cost_per_lead_qualified).format('$0,0') : "-";

			return data;
		}
	}
}