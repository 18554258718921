<template>
	<div class="tooltip">
		<TooltipButton />
	</div>
</template>

<script>
	import TooltipButton from '@/components/TooltipButton';

	export default {
		name: 'Tooltip',
		components: {
			TooltipButton
		}
	}

</script>

<style lang="scss">

</style>