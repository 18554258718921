<template>
  <Sidebar
    class="ad-report-sidebar"
    :active="active"
    @dismiss="$emit('dismiss')"
  >
    <template v-slot:header>
      <h2>Performance Details</h2>
    </template>

    <template v-slot:tabs>
      <div class="button-group">
        <button
          class="button-group__button"
          :class="{ active: activeTab === 'alerts' }"
          @click="activeTab = 'alerts'"
        >
          Performance Alerts
        </button>
        <button
          class="button-group__button"
          :class="{ active: activeTab === 'metrics' }"
          @click="activeTab = 'metrics'"
        >
          Performance Metrics
        </button>
      </div>
    </template>

    <template v-if="ad">
      <template v-if="activeTab === 'alerts'">
        <SlideToggle
          class="ad-alert"
          v-for="(alert, index) in ad.alerts"
          :key="`alert-index-${index}`"
          :disabled="!alert.recommendations.length"
        >
          <template v-slot:header="slotProps">
            <div class="ad-alert__header">
              <Pill
                v-if="alert.recommendations.length"
                :count="alert.recommendations.length"
              />
              <CircleIcon
                v-else
                :icon="'warning'"
                :alert="true"
                :pinned="true"
              />
              {{ alert.message }}
              <div class="ad-alert__icon" v-if="alert.recommendations.length">
                <BaseSVG
                  v-if="!slotProps.active"
                  :src="require('@/assets/angle-down-icon.svg')"
                />
                <BaseSVG v-else :src="require('@/assets/angle-up-icon.svg')" />
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="ad-alert__body">
              <div
                class="card"
                v-for="(recommendation, index) in alert.recommendations"
                :key="`recommendation-${index}`"
              >
                <div class="ad-alert__body-header">
                  <CircleIcon
                    v-if="recommendation.category === 'ad'"
                    :icon="'ad'"
                    :pinned="true"
                  />
                  <CircleIcon
                    v-else-if="recommendation.category === 'view'"
                    :icon="'lander'"
                    :pinned="true"
                  />
                  <CircleIcon
                    v-else-if="recommendation.category === 'segmentation'"
                    :icon="'audience'"
                    :pinned="true"
                  />
                  <div class="ad-alert__body-header-heading">
                    {{ recommendation.heading }}
                  </div>
                  <div class="ad-alert__body-header-subheading">
                    {{ recommendation.type }}
                    {{ recommendation.category }} adjustment
                  </div>
                </div>
                {{ recommendation.description }}
                <div class="card__footer">
                  Estimated Impact
                  <ImpactGauge :impact="recommendation.impact" />
                </div>
              </div>
            </div>
          </template>
        </SlideToggle>
      </template>
      <template v-else>
        <div
          class="metrics"
          v-for="(item, key) in metrics"
          :key="`metrics-${key}`"
        >
          <div class="metrics__title">{{ item.title }}</div>
          <SimpleMetric
            v-for="(metric, metricKey, i) in metrics[key].metrics"
            :key="`metric-${key}-${metricKey}-${i}`"
            :label="metric.label"
            :measure="ad.labels[metric.measure]"
            :context="metric.context ? ad.labels[metric.context] : '-'"
            :alert="
              metric.benchmark
                ? $_benchmarks_compareBenchmark(
                    metric.benchmark,
                    ad[metric.benchmark]
                  )
                : null
            "
            :list="true"
          />
        </div>
      </template>
    </template>
  </Sidebar>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";
import CircleIcon from "@/components/CircleIcon";
import Sidebar from "@/components/Sidebar";
import SimpleMetric from "@/components/SimpleMetric";
import SlideToggle from "@/components/SlideToggle";
import { adBenchmarks } from "@/mixins/adBenchmarks";
import adReportMetrics from "@/data/adReportMetrics.json";
import Pill from "@/components/Pill";
import ImpactGauge from "@/components/ImpactGauge";

export default {
  name: "AdReportSidebar",
  mixins: [adBenchmarks],
  components: {
    CircleIcon,
    BaseSVG,
    Sidebar,
    SimpleMetric,
    SlideToggle,
    Pill,
    ImpactGauge,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
      required: true,
    },
    campaign: {
      type: Object,
      default: () => ({}),
    },
    ad: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeTab: "alerts",
    };
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_REMOTE_FRONTEND_BASE;
    },
    metrics() {
      return adReportMetrics;
    },
  },
  methods: {
    onToggleExperiment() {
      this.$emit("on-toggle-experiment");
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";

.ad-report-sidebar {
  h2 {
    font-weight: 600;
    button {
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 0;
    }
    svg {
      width: 16px;
      fill: $medium-gray;
    }
  }

  .metrics {
    &:not(:first-of-type) {
      margin-top: 1rem;
    }

    &__title {
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .ad-report__heading {
    width: 100%;
    padding-right: 0;

    &-label {
      font-weight: 600;
    }
  }

  .ad-alert {
    margin: 0 -2rem;
    padding: 2rem;
    font-size: 14px;
    line-height: 1.25;
    border-bottom: solid 1px $fade-gray;

    &:first-of-type {
      margin-top: -2rem;
    }

    &__header {
      position: relative;
      padding-left: 48px;
      padding-right: 48px;
      font-weight: 600;

      .pill {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -12px;
      }
    }

    &__body {
      padding-top: 2rem;

      h3 {
        font-weight: 900;
        color: $dark-blue;
        margin-bottom: 8px;
      }

      .card {
        padding: 1.75rem 1.75rem calc(1.75rem + 48px) 1.75rem !important;
        position: relative;
        margin-bottom: 1rem;
        /** Gross hacks for shadow */
        width: calc(100% - 12px);
        margin-left: 6px;
        color: $body-gray;
        border: solid 1px $fade-gray;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075) !important;

        &:last-of-type {
          margin-bottom: 6px;
        }

        &__footer {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: #f7f7f7;
          height: 48px;
          border-radius: 0 0 8px 8px;
          display: flex;
          align-items: center;
          padding: 0 1.75rem;
          font-weight: 600;
          color: $green;

          svg {
            fill: $medium-gray;
            width: 12px;
            margin-right: 6px;
          }

          .impact-gauge {
            margin-left: auto;
          }

          &-button {
            border: none;
            background: transparent;
            padding: 0;
            font-family: inherit;
            cursor: pointer;
            color: inherit;
          }
        }
      }

      &-header {
        position: relative;
        display: flex;
        flex-direction: column;
        font-weight: 600;
        margin-bottom: 1.5rem;
        color: $gray;
        font-size: 15px;
        padding-left: 48px;

        /**@TODO This is duplicative - create component */
        &-icon {
          left: 0;
          position: absolute;
          width: 36px;
          height: 36px;
          background: $fade-green;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: 8px;

          svg {
            width: 14px;
            height: auto;
            path {
              stroke: $green;
            }
          }
        }

        &-subheading {
          color: $medium-gray;
          font-size: 13px;
          font-weight: 400;
          text-transform: capitalize;
          margin-top: 2px;
        }
      }
    }

    &__icon {
      position: absolute;
      right: 16px;
      top: 50%;
      margin-top: -6px;
      width: 12px;
      height: 12px;

      svg {
        width: 100%;
        height: 100%;
        fill: $medium-gray;
      }
    }

    /**@TODO This is duplicative - create component */
  }

  .button-group {
    display: flex;
    height: 40px;

    &__button {
      height: 40px;
      flex: 1 1 0px;
      justify-content: center;
      border: none;
      border-bottom: solid 1px $disabled;
      background: transparent;
      cursor: pointer;
      font-family: inherit;
      padding: 0.75em 0;
      color: $medium-gray;
      position: relative;
      font-weight: 600;

      &.active {
        border-bottom-color: $light-blue;
        color: $gray;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: $light-blue;
        }
      }

      .pill {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }

    & + .metrics {
      margin-top: 2rem;
    }
  }
}
</style>
