<template>
	<ul class="list-legend">
		<ListLegendItem v-for="(item, index) in list" :key="`value-${index}`" :item="item" />
	</ul>
</template>

<script>
	import ListLegendItem from '@/components/ListLegendItem';

	export default {
		name: 'ListLegend',
		props: [ 'list' ],
		components: {
			ListLegendItem
		}
	}
</script>

<style lang="scss">
	@import '@/scss/colors';
	
	.list-legend {
		.list-legend-item {
			&:nth-child(1) {
				.list-legend-item-indicator {
					background: $light-blue;
				}
			}

			&:nth-child(2) {
				.list-legend-item-indicator {
					background: $yellow;
				}
			}

			&:nth-child(3) {
				.list-legend-item-indicator {
					background: $purple;
				}
			}

			&:nth-child(4) {
				.list-legend-item-indicator {
					background: $dark-blue;
				}
			}

			&:nth-child(5) {
				.list-legend-item-indicator {
					background: $orange;
				}
			}

			&:nth-child(6) {
				.list-legend-item-indicator {
					background: $green;
				}
			}

			&:nth-child(7) {
				.list-legend-item-indicator {
					background: $blue;
				}
			}

			&:nth-child(8) {
				.list-legend-item-indicator {
					background: $medium-gray;
				}
			}

			&:nth-child(9) {
				.list-legend-item-indicator {
					background: $dark-blue-bg;
				}
			}
		}
	}
</style>