// Create global mixins
export const adBenchmarks = {
    data() {
        return {
            $_benchmarks: {
                click_through_rate: {
                    value: 0.015,
                    operand: "<",
                    message: "Ad click through rate is lower than the established benchmark",
                    recommendations: {
                        segmentation: {
                            audience: [

                            ]
                        },
                        ad: {
                            social: [
                                {
                                    heading: "Draw More Attention",
                                    description: "The ad may not be drawing enough attention. Try using size, color, shape, or motion to grab attention stand out.",
                                    impact: 2
                                },
                                {
                                    heading: "Generate More Interest",
                                    description: "Does the offer resonate with the target audience? Does the offer appeal to the consumer? Can the consumer get this offer elsewhere? Is the offer credible? Is the offer clear?",
                                    impact: 1
                                },
                                {
                                    heading: "Ask for the Click",
                                    description: "Be sure to ask for the click whether it is implied or direct.",
                                    impact: 1
                                },
                            ],
                            search: [
                                {
                                    heading: "Add Negative Keywords",
                                    description: "Negative keywords will prevent ads from being served based on unrelated search terms.",
                                    impact: 2
                                },
                                {
                                    heading: "Match Common Search Phrases",
                                    description: "Make sure the ad copy matches common search phrases.",
                                    impact: 3
                                }
                            ]
                        },
                        view: {
                            lander: [

                            ],
                            email: [

                            ]
                        }
                    }
                },
                cost_per_click: {
                    value: 3.00,
                    operand: ">",
                    message: "Ad cost per click is higher than the established benchmark",
                    recommendations: {
                        segmentation: {
                            audience: [

                            ]
                        },
                        ad: {
                            social: [

                            ],
                            search: [

                            ]
                        },
                        view: {
                            lander: [

                            ],
                            email: [

                            ]
                        }
                    }
                },
                cost_per_lead: {
                    value: 90.00,
                    operand: ">",
                    message: "Cost per lead is higher than the established benchmark",
                    recommendations: {
                        segmentation: {
                            audience: [

                            ]
                        },
                        ad: {
                            social: [

                            ],
                            search: [

                            ]
                        },
                        view: {
                            lander: [

                            ],
                            email: [

                            ]
                        }
                    }
                },
                cost_per_lead_won: {
                    value: 1000.00,
                    operand: ">",
                    message: "Cost per lead won is higher than the established benchmark",
                    recommendations: {
                        segmentation: {
                            audience: [

                            ]
                        },
                        ad: {
                            social: [

                            ],
                            search: [

                            ]
                        },
                        view: {
                            lander: [

                            ],
                            email: [

                            ]
                        }
                    }
                },
                average_engaged_session_duration: {
                    value: 45, // seconds
                    operand: "<",
                    message: "Average engaged session duration is lower than the established benchmark",
                    recommendations: {
                        segmentation: {
                            audience: [

                            ]
                        },
                        ad: {
                            social: [

                            ],
                            search: [

                            ]
                        },
                        view: {
                            lander: [

                            ],
                            email: [

                            ]
                        }
                    }
                },
                average_engaged_session_scroll_depth: {
                    value: 0.50,
                    operand: "<",
                    message: "Average engaged session scroll depth is lower than the established benchmark",
                    recommendations: {
                        segmentation: {
                            audience: [

                            ]
                        },
                        ad: {
                            social: [

                            ],
                            search: [

                            ]
                        },
                        view: {
                            lander: [

                            ],
                            email: [

                            ]
                        }
                    }
                },
                engagement_rate: {
                    value: 0.50,
                    operand: "<",
                    message: "Landing page visitors are not engaging with the page content",
                    recommendations: {
                        segmentation: {
                            audience: [

                            ]
                        },
                        ad: {
                            social: [

                            ],
                            search: [

                            ]
                        },
                        view: {
                            lander: [
                                {
                                    heading: "Increase Motivation",
                                    description: "Be sure the message is relevant to the consumer and the landing page reinforces the message of the ad.",
                                    impact: 4
                                },
                                {
                                    heading: "Build Structure",
                                    description: "The page content should be ordered in a logical, impactful way. First, describe the value and the benefits. Then, support these claims with social proof or expert knowledge.",
                                    impact: 2
                                },
                                {
                                    heading: "Show the Benefits",
                                    description: "Choose imagery that reinforces the benefits of the offer. This includes imagery that captures the emotional state our visitors are trying to achieve.",
                                    impact: 1
                                },
                                {
                                    heading: "Stay Consistent",
                                    description: "Be sure the page content is consistent with both brand guidelines and the ad that led the visitor to the page.",
                                    impact: 1
                                }
                            ],
                            email: [

                            ]
                        }
                    }
                },
                conversion_rate: {
                    value: 0.04,
                    operand: "<",
                    message: "Landing page visitors are not showing interest in the offer",
                    recommendations: {
                        segmentation: {
                            audience: [

                            ]
                        },
                        ad: {
                            social: [

                            ],
                            search: [

                            ]
                        },
                        view: {
                            lander: [
                                {
                                    heading: "Increase Value",
                                    description: "Be sure the offer is strong enough to outweigh both friction and anxiety. Basically, is the incentive of the offer worth the effort?",
                                    impact: 3
                                },
                                {
                                    heading: "Reduce Friction",
                                    description: "The level of friction should be appropriate to the value of the offer. Reduce the number of required steps and form fields. Also, ask for the conversion often to make it easy for visitors to claim the offer.",
                                    impact: 2
                                },
                                {
                                    heading: "Build Trust",
                                    description: "Visitors may worry that the offer is spamish or their information is not secure. Reduce anxiety with social proof including testimonials, pseudomonials, and expert endorsements.",
                                    impact: 2
                                },
                                {
                                    heading: "Draw Attention",
                                    description: "Use color, negative space, and directional cues to draw attention to each call to action.",
                                    impact: 1
                                }
                            ],
                            email: [

                            ]
                        }
                    }
                },
                conversion_rate_won: {
                    value: 0.01,
                    operand: "<",
                    message: "Leads are not converting to hand-offs",
                    recommendations: {
                        segmentation: {
                            audience: [
                                {
                                    heading: "Target Higher Access",
                                    description: "If payer type qualification is an issue, target geodemographic segments that have higher healthcare access.",
                                    impact: 2
                                },
                                {
                                    heading: "Limit Geography",
                                    description: "If travel distance is an issue, reduce the geographic distance of the target audience from provider locations.",
                                    impact: 1
                                }
                            ]
                        },
                        ad: {
                            social: [

                            ],
                            search: [

                            ]
                        },
                        view: {
                            lander: [

                            ],
                            email: [

                            ]
                        }
                    }
                }
            },
            $_benchmarks_operators: {
                '=': (a, b) => { return a == b },
                '<': (a, b) => { return a < b },
                '>': (a, b) => { return a > b },
            },
        };
    },
    methods: {
        $_benchmarks_compareBenchmark(key, value) {
            try {
                if (typeof this.$data.$_benchmarks[key] != 'undefined') {
                    return this.$data.$_benchmarks_operators[this.$data.$_benchmarks[key].operand](value, this.$data.$_benchmarks[key].value);
                }
            } catch (e) {
                console.log(e);
            }

            return false;
        },
        $_benchmarks_compareBenchmarks(metrics, adType, viewType) {
            let alerts = [];
            for (let key in this.$data.$_benchmarks) {
                if (this.$_benchmarks_compareBenchmark(key, metrics[key])) {
                    let item = {
                        message: this.$data.$_benchmarks[key].message,
                        recommendations: []
                    }


                    // Add segmentation recs
                    for (let segmentationKey in this.$data.$_benchmarks[key].recommendations.segmentation) {
                        item.recommendations = [...item.recommendations, ...this.$data.$_benchmarks[key].recommendations.segmentation[segmentationKey].map((item) => {
                            return { ...item, category: 'segmentation', type: segmentationKey };
                        })];
                    }

                    // Add ad recs
                    item.recommendations = [...item.recommendations, ...this.$data.$_benchmarks[key].recommendations.ad[adType].map((item) => {
                        return { ...item, category: 'ad', type: adType };
                    })];

                    // Ad view recs
                    if (viewType) {
                        item.recommendations = [...item.recommendations, ...this.$data.$_benchmarks[key].recommendations.view[viewType].map((item) => {
                            return { ...item, category: 'view', type: viewType }
                        })];
                    }

                    alerts.push(item);
                }
            }

            return alerts;
        }
    }
};