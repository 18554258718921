<template>
  <li class="list-legend-item">
    <div class="list-legend-item-wrapper">
      <span class="list-legend-item-indicator" :style="itemStyle"></span>
      <span class="list-legend-item-label">{{ item.label }}</span>
    </div>
    <span class="list-legend-item-metric">{{ item.total | number }}</span>
  </li>
</template>

<script>
export default {
  name: "ListLegendItem",
  props: ["item"],
  computed: {
    itemStyle() {
      if (this.item.color) {
        return {
          "background-color": this.item.color,
        };
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.list-legend-item {
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 3px;
  padding: 3px 0 6px 0;
  border-bottom: solid 1px $fade-gray;

  &-wrapper {
    display: flex;
    align-items: center;

    .list-legend-item-indicator {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #e0e0e0;
      margin-right: 6px;
    }

    .list-legend-item-label {
      font-weight: 600;
    }
  }

  &-metric {
    color: $medium-gray;
    margin-left: auto;
  }
}
</style>