export const maps = {
    data() {
        return {
            $_maps_features: [],
        }
    },
    methods: {
        $_maps_drawGeometry(arr, map, google, setStyle, onClick) {
            if (arr.length && google) {
                // Reset the features
                this.$data.$_maps_features.map((feature) => {
                    map.$mapObject.data.remove(feature[0]);
                });
                this.$data.$_maps_features.splice(0);
                this.google.maps.event.clearListeners(map.$mapObject.data, 'click');

                this.$nextTick(() => {
                    // Create bounds
                    let bounds = new google.maps.LatLngBounds();

                    arr.map((item) => {
                        if (item.geom) {
                            const feature = map.$mapObject.data.addGeoJson({
                                type: "Feature",
                                geometry: item.geom,
                                properties: item.properties,
                            });

                            this.$data.$_maps_features.push(feature);
                            this.$_maps_processPoints(
                                feature[0].getGeometry(),
                                bounds.extend,
                                bounds,
                                google
                            );

                            if (typeof setStyle == 'function') {
                                map.$mapObject.data.setStyle((feature) => {
                                    return setStyle(feature);
                                });
                            }
                        }
                    });

                    map.fitBounds(bounds, 0);

                    if (typeof onClick == 'function') {
                        map.$mapObject.data.addListener('click', (event) => {
                            onClick(event.feature);
                        });
                    }
                });
            }
        },
        $_maps_processPoints(geometry, callback, thisArg, google) {
            if (geometry instanceof google.maps.LatLng) {
                callback.call(thisArg, geometry);
            } else if (geometry instanceof google.maps.Data.Point) {
                callback.call(thisArg, geometry.get());
            } else {
                geometry.getArray().forEach((g) => {
                    this.$_maps_processPoints(g, callback, thisArg, google);
                });
            }
        },
        $_maps_zoomToBounds(key, map, google) {
            if (typeof map != undefined) {
                // Create bounds
                let bounds = new google.maps.LatLngBounds();
                let filtered = this.$data.$_maps_features.filter((item) => {
                    for (let k in key) {
                        return item[0].getProperty(k) == key[k] || !key[k];
                    }
                });

                filtered.map((item) => {
                    this.$_maps_processPoints(
                        item[0].getGeometry(),
                        bounds.extend,
                        bounds,
                        google
                    );
                });

                map.fitBounds(bounds, 0);
            }
        }
    }
}