<template>
  <div class="grid-chart">
    <div class="grid-chart__y-axis">
      <div
        class="grid-chart__y-axis-label"
        v-for="(item, index) in yAxisLabels"
        :key="`y-axis-label-${index}`"
      >
        {{ item }}
      </div>
    </div>
    <div class="grid-chart__items">
      <div
        class="grid-chart__row"
        v-for="(row, rowIndex) in rows"
        :key="`row-${rowIndex}`"
      >
        <GridChartItem
          v-for="(item, itemIndex) in row"
          :key="`row-${rowIndex}-item-${itemIndex}`"
          :metric="item"
          :max="max"
          :total="total"
        />
      </div>
    </div>
    <div class="grid-chart__x-axis">
      <div
        class="grid-chart__x-axis-label"
        v-for="(item, index) in xAxisLabels"
        :key="`x-axis-label-${index}`"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import GridChartItem from "@/components/charts/GridChartItem";

export default {
  name: "GridChart",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  components: {
    GridChartItem,
  },
  computed: {
    yAxisLabels() {
      return this?.chartData?.labels[0] || [];
    },
    xAxisLabels() {
      return this?.chartData?.labels[1] || [];
    },
    rows() {
      return this?.chartData?.data || [];
    },
    total() {
      let merged = [].concat.apply([], this.chartData.data);
      return merged.reduce((total, item) => {
        return (total += parseFloat(item));
      }, 0);
    },
    max() {
      let merged = [].concat.apply([], this.chartData.data);
      return Math.max(...merged);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.grid-chart {
  &__y-axis {
    width: 128px;
    float: left;
    border-right: solid 2px $fade-gray;

    &-label {
      height: 34px;
      display: flex;
      align-items: center;
      font-size: 13px;
      color: $medium-gray;
    }
  }
  &__items {
    width: calc(100% - 128px);
    float: left;
  }
  &__row {
    display: flex;
  }
  &__x-axis {
    width: calc(100% - 126px);
    margin-left: 126px;
    padding-left: 2px;
    display: flex;
    border-top: solid 2px $fade-gray;

    &-label {
      padding-top: 16px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      font-size: 13px;
      color: $medium-gray;
    }
  }
}
</style>