<template>
  <div class="ad-report">
    <div class="ad-report__icon" :class="{ alert: alerts.length }">
      <CircleIcon
        v-if="alerts.length"
        :icon="'warning'"
        :alert="true"
        :pinned="true"
        :xl="true"
      />
      <CircleIcon v-else :icon="'check-mark'" :xl="true" />
    </div>
    <div class="card" @click="onAdReport">
      <BaseSVG
        class="ad-report__arrow-icon"
        :src="require('@/assets/arrow-45-icon.svg')"
      />
      <h2>
        <div class="ad-report__heading">
          <div class="ad-report__heading-label">
            <CircleIcon :icon="'ad'" />
            <a @click.stop="onPreview(ad.preview_url)" v-if="ad.preview_url">{{
              ad.name
            }}</a>
            <span v-else>{{ ad.name }}</span>
          </div>
          <div class="ad-report__heading-label">
            <CircleIcon :icon="'lander'" />
            <a
              @click.stop="
                onPreview(`${baseURL}/${campaign.slug}/${ad.view.slug}`)
              "
              v-if="ad.view"
              >{{ ad.view.name }}</a
            >
            <span v-else>{{ ad.view.name }}</span>
          </div>
        </div>

        <div class="ad-report__metrics">
          <SimpleMetric
            :label="'Total Leads'"
            :measure="metrics.labels.total_leads"
            :context="metrics.labels.conversion_rate"
            :alert="
              $_benchmarks_compareBenchmark(
                'conversion_rate',
                metrics.conversion_rate
              )
            "
          />
          <SimpleMetric
            :label="'Leads Won'"
            :measure="metrics.labels.total_leads_won"
            :context="metrics.labels.conversion_rate_won"
            :alert="
              $_benchmarks_compareBenchmark(
                'conversion_rate_won',
                metrics.conversion_rate_won
              )
            "
          />
          <SimpleMetric
            :label="'CPL Won'"
            :measure="metrics.labels.cost_per_lead_won"
            :alert="
              $_benchmarks_compareBenchmark(
                'cost_per_lead_won',
                metrics.cost_per_lead_won
              )
            "
          />
        </div>
      </h2>
    </div>
  </div>
</template>

<script>
import CircleIcon from "@/components/CircleIcon";
import BaseSVG from "@/components/base/BaseSVG";
import SimpleMetric from "@/components/SimpleMetric";
import { colors } from "@/mixins/colors";
import { reports } from "@/mixins/reports";
import { adBenchmarks } from "@/mixins/adBenchmarks";

export default {
  name: "AdReport",
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
  mixins: [colors, reports, adBenchmarks],
  components: {
    SimpleMetric,
    BaseSVG,
    CircleIcon,
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_REMOTE_FRONTEND_BASE;
    },
    campaign() {
      return this.$store.getters["optimize/campaign"];
    },
    metrics() {
      return this.$_reports_reduceAdReports(this.ad.reports);
    },
    alerts() {
      if (this.metrics) {
        return this.$_benchmarks_compareBenchmarks(
          this.metrics,
          this.ad.type,
          this?.ad?.view?.type
        );
      }

      return [];
    },
  },
  methods: {
    onAdReport() {
      this.$emit("on-ad-report", {
        ...this.ad,
        ...this.metrics,
        alerts: this.alerts,
      });
    },
    onPreview(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.ad-report {
  position: relative;
  padding-left: 64px;

  .card {
    cursor: pointer;
    transition: transform 0.425s, box-shadow 0.425s;

    &:hover {
      transform: scale(1.0175);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1) !important;
    }
  }
  h2 {
    margin-bottom: 0 !important;
  }

  &__arrow-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 0.75rem;
    path {
      stroke: $medium-gray;
    }
  }

  &__heading {
    width: 50%;
    padding-right: 1rem;

    &-label {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .circle-icon {
        margin-right: 8px;
      }

      .pill {
        margin-right: 0.5rem;
      }

      a {
        color: $blue;
        text-decoration: underline;

        &:hover {
          color: $light-blue;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__metrics {
    display: flex;
    align-items: center;
    width: 50%;

    .simple-metric {
      width: 33.3333%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>