function AdReport() {
    return {
        clicks: 0,
        impressions: 0,
        reach: 0,
        frequency: 0,
        click_through_rate: 0,
        total_sessions: 0,
        total_unique_sessions: 0,
        total_engaged_sessions: 0,
        engagement_rate: 0,
        average_session_duration: 0,
        average_unique_session_duration: 0,
        average_engaged_session_duration: 0,
        average_session_scroll_depth: 0,
        average_engaged_session_scroll_depth: 0,
        total_leads: 0,
        total_leads_inbound: 0,
        total_leads_outbound: 0,
        total_leads_won: 0,
        total_leads_won_inbound: 0,
        total_leads_won_outbound: 0,
        conversion_rate: 0,
        conversion_rate_won: 0,
        conversion_rate_won_inbound: 0,
        conversion_rate_won_outbound: 0,
        total_cost: 0,
        cost_per_lead: 0,
        cost_per_lead_won: 0,
        total_leads_invalid: 0,
        total_leads_invalid_inbound: 0,
        total_leads_invalid_outbound: 0,
        total_leads_invalid_rate: 0,
        total_leads_invalid_inbound_rate: 0,
        total_leads_invalid_outbound_rate: 0,
        total_leads_qualified: 0,
        total_leads_qualified_inbound: 0,
        total_leads_qualified_outbound: 0,
        total_leads_qualified_rate: 0,
        total_leads_qualified_inbound_rate: 0,
        total_leads_qualified_outbound_rate: 0,
        total_leads_unqualified: 0,
        total_leads_unqualified_inbound: 0,
        total_leads_unqualified_outbound: 0,
        total_leads_unqualified_rate: 0,
        total_leads_unqualified_inbound_rate: 0,
        total_leads_unqualified_outbound_rate: 0,
        total_leads_unqualified_insurance: 0,
        total_leads_unqualified_insurance_inbound: 0,
        total_leads_unqualified_insurance_outbound: 0,
        total_leads_unqualified_insurance_rate: 0,
        total_leads_unqualified_insurance_inbound_rate: 0,
        total_leads_unqualified_insurance_outbound_rate: 0,
        total_leads_connected: 0,
        total_leads_connected_inbound: 0,
        total_leads_connected_outbound: 0,
        total_leads_connected_rate: 0,
        total_leads_connected_inbound_rate: 0,
        total_leads_connected_outbound_rate: 0,
        cost_per_lead_connected: 0,
        cost_per_lead_qualified: 0,
        labels: {
            clicks: null,
            impressions: null,
            reach: null,
            frequency: null,
            click_through_rate: null,
            total_sessions: null,
            total_unique_sessions: null,
            total_engaged_sessions: null,
            engagement_rate: null,
            average_session_duration: null,
            average_unique_session_duration: null,
            average_engaged_session_duration: null,
            average_session_scroll_depth: null,
            average_engaged_session_scroll_depth: null,
            total_leads: null,
            total_leads_inbound: null,
            total_leads_outbound: null,
            total_leads_won: null,
            total_leads_won_inbound: null,
            total_leads_won_outbound: null,
            conversion_rate: null,
            conversion_rate_won: null,
            conversion_rate_won_inbound: null,
            conversion_rate_won_outbound: null,
            total_cost: null,
            cost_per_lead: null,
            cost_per_lead_won: null,
            total_leads_invalid: null,
            total_leads_invalid_inbound: null,
            total_leads_invalid_outbound: null,
            total_leads_invalid_rate: null,
            total_leads_invalid_inbound_rate: null,
            total_leads_invalid_outbound_rate: null,
            total_leads_qualified: null,
            total_leads_qualified_inbound: null,
            total_leads_qualified_outbound: null,
            total_leads_qualified_rate: null,
            total_leads_qualified_inbound_rate: null,
            total_leads_qualified_outbound_rate: null,
            total_leads_unqualified: null,
            total_leads_unqualified_inbound: null,
            total_leads_unqualified_outbound: null,
            total_leads_unqualified_rate: null,
            total_leads_unqualified_inbound_rate: null,
            total_leads_unqualified_outbound_rate: null,
            total_leads_unqualified_insurance: null,
            total_leads_unqualified_insurance_inbound: null,
            total_leads_unqualified_insurance_outbound: null,
            total_leads_unqualified_insurance_rate: null,
            total_leads_unqualified_insurance_inbound_rate: null,
            total_leads_unqualified_insurance_outbound_rate: null,
            total_leads_connected: null,
            total_leads_connected_inbound: null,
            total_leads_connected_outbound: null,
            total_leads_connected_rate: null,
            total_leads_connected_inbound_rate: null,
            total_leads_connected_outbound_rate: null,
            cost_per_lead_connected: null,
            cost_per_lead_qualified: null,
        }
    }
}

export default AdReport;