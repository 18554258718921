<template>
	<span class="tooltip-button">?</span>
</template>

<script>

	export default {
		name: 'TooltipButton'
	}

</script>

<style lang="scss">
	@import '@/scss/colors';

	.tooltip-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1rem;
		height: 1rem;
		background-color: #f5f5f5;
		color: $medium-gray;
		font-size: 10px;
		border-radius: 50%;
		cursor: pointer;
	}

	.highlight {
		.tooltip-button {
			background-color: rgba(255, 255, 255, 0.35);
			color: $dark-blue-bg;
		}
	}
</style>